<template>
    <div class="menu">
        <div class="container menu_box">
            <div class="menu_left">
                <div class="zz">
                    <img class="logo" alt="" :src="img_url + getClient.channel_logo"  width="100%">
                </div>
                <div class="fgx2"></div>
                <img class="logo2" alt="" :src="img_url + getClient.channel_log_bs" height="100%">
            </div>
            <div class="menu_right">
                <ul>
                    <li class="item_li">
                        <a @click="toHome" class="link">网站首页</a>
                        <span class="link_span">HOME</span>
                    </li>
                    <li>
                        <router-link to="/member" class="link">会员中心</router-link>
                        <span class="link_span">MEMBER</span>
                    </li>
                    <li>
                        <a  class="link" @click="click_wallet">平台充值</a>
                        <span class="link_span">RECHARGE</span>
                    </li>
                    <!-- <li>
                        <a  class="link"  @click="click_atlas">通关成就</a>
                        <span class="link_span">ATLAS</span>
                    </li> -->
                    <!-- <li>
                        <router-link to="/welfareGift" class="link">福利礼包</router-link>
                        <span class="link_span">GIFT BAG</span>
                    </li>-->
                    <!-- <li>
                        <a @click="toPointsMall" class="link">灵符商城</a>
                        <span class="link_span">MALL</span>
                    </li> -->
                    <li>
                        <router-link to="/information" class="link">新闻资讯</router-link>
                        <span class="link_span">OURMALISM</span>
                    </li>
                    <li>
                        <a @click="toCharts" class="link">排行榜</a>
                        <span class="link_span">CHARTS</span>
                    </li>
                    <li v-if="show_tutoral">
                        <router-link to="/tutorial" class="link">新手教程</router-link>
                        <span class="link_span">COURSE</span>
                    </li> 
                    <!-- <li>
                        <router-link to="/member/level" class="link">等级福利</router-link>
                        <span class="link_span">WELFARE</span>
                    </li> -->
                    <!-- <li>
                        <a href="javascript:0" class="link">关于我们</a>
                        <span class="link_span">ABOUT US</span>
                    </li> -->
                    <li>
                        <router-link to="/helps" class="link">帮助</router-link>
                        <span class="link_span">HELP</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name:'Navigation',
        data() {
            return {
                show_tutoral:false
            }
        },
        computed:{
            ...mapState([
                'getClient',
            ])
        },
        methods:{
            click_atlas(){
                this.$router.push({
                    path:'/atlas'
                })
                this.$czc( 'PC子页面导航通关成就', '点击', '访问通关成就')
            },
            click_wallet(){
                this.$router.push({
                    path:'/member/wallet'
                })
                this.$czc('PC子页面导航平台充值', '点击', '访问平台充值')
            },
            toHome(){
                this.$router.push({
                    path:'/home'
                })
            },
            toPointsMall(){
                this.$router.push({
                    path:'/pointsMall'
                })
                localStorage.setItem('mall_id','1')
                this.$czc( 'PC子页面灵符商城', '点击', '访问灵符商城')
            },
            toCharts(){
                this.$router.push({
                    path:'/chartsing'
                })
                this.$czc( 'PC子页面排行榜', '点击', '访问排行榜')
            }
        },
        mounted(){
            const material = JSON.parse(localStorage.getItem('material'))
            if(material.pc_tutorial_banner.img || material.pc_tutorial_banners.img || material.pc_tutorial_bannerss.img){
                this.show_tutoral = true
            }else{
                this.show_tutoral = false
            }
        }
    }
</script>

<style scoped>
    /* 菜单 */
    .zz{
    width: 149px;
    text-indent: 210px;
    overflow: hidden;
    }
    .logo{
        filter: drop-shadow(-210px 0 #B5945B);
        
    }
    .menu{
        width:100%;
        background: #FFFFFF;
        display: flex;
        align-items: center;
    }
    .menu_box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 15px auto;
        padding: 0;
    }
    .fgx2{
        width: 1px;
        height: 50px;
        background: #B5945B;
        opacity: 0.3;
        margin: 0 20px;
    }
    .menu_left{
        display: flex;
        justify-content: start;
        flex-direction: row;
        align-items: center;
    }
    .menu_right ul{
        display: flex;
        flex-direction: row;
        justify-content: end;
        margin: 0;
        padding: 0;
    }
    .menu_right ul li{
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 30px;
    }
    
    .link{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #4D4D4D;
        margin-bottom: 7px;
        text-decoration: none;
        transition: all 0.5s;
        margin: 0;
    }
    .link:hover{
        color: #b4935a;
        transition: all 0.5s;
    }
    .link:hover + .link_span {
        color: #b4935a;
        transition: all 0.5s;
    }
    .link_span{
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #B2B2B2;
        transition: all 0.5s;
        cursor: pointer;
    }
    @media (max-width:1600px) {
        .zz{
            width: 149px;
        }
        .link{
            font-size: 14px;
        }
    }
    
    @media (max-width:1300px) {
        .zz{
            width: 110px;
        }
        .logo2{
            width: 40%;
        }
        .menu_box{
            margin: 10px auto;
        }
        .menu_right ul li{
            margin-left: 20px;
        }
        .link{
            font-size: 12px;
        }
    }
    @media (max-width:1100px) {
        .zz{
            width: 100px;
        }
        .menu_right ul li{
            margin-left: 10px;
        }
        .fgx2{
            margin: 0 15px;
        }
    }
</style>