<template>
  <div>
    <GameNavigation></GameNavigation>
    <div class="game_details">
      <div class="container">
        <div class="row">
          <div class="col-8">
            <div class="game_content" ref="game_content" v-resize="divResize">
              <img class="model_under" v-if="game_details.is_under == 1" :src="img_url + material.pc_under_icon">
              <div class="big_box">
                <!-- 点赞 -->
                <div class="like">
                  <div class="zan">
                    <img v-if="game_details.is_zan" class="islike" src="/GameDetails/img/good.png" @click="clickZang">
                    <img v-else class="islike" src="/GameDetails/img/isgood.png" @click="clickZang">
                    <span class="like_title">{{ game_details.zan_num ? game_details.zan_num : 0 }}</span>
                    <span id="is_zan" class="add_num">+1</span>
                  </div>
                  <div class="zan">
                    <img v-if="game_details.is_cai" class="islike" src="/GameDetails/img/bad.png" @click="clickCai">
                    <img v-else class="islike" src="/GameDetails/img/isbad.png" @click="clickCai">
                    <span class="like_title">{{ game_details.zan_num ? game_details.cai_num : 0 }}</span>
                    <span id="is_cai" class="add_num">+1</span>
                  </div>
                </div>
                <img class="banner" v-lazy="img_url + game_src" width="100%">
              </div>
              <div class="content_box">
                <div class="content_top">
                  <div class="content_title">
                    <h4>{{ name }}</h4>
                    <span class="">{{ game_title }}</span>
                  </div>
                  <div class="content_btn">
                    <a class="down_btn btn_color_down btn_font text_ellipsis" @click="downloadFile(down)">下载游戏</a>
                    <!-- <a class="down_btn btn_color btn_font text_ellipsis">在线充值</a> -->
                    <a class="down_btn btn_color btn_font text_ellipsis"
                      @click="receiveBenefits(game_details.game_id)">兑换充值</a>
                    <a :href="web" class="down_btn btn_color btn_font text_ellipsis" target="_blank">官方网站</a>
                    <a v-if="game_details.user_m_status" class="down_btn btn_color btn_font text_ellipsis"
                      @click="jion">参与通关</a>
                    <a v-else class="down_btn btn_color btn_font text_ellipsis isClick">已参与</a>
                  </div>
                </div>
                <div class="time_box">
                  <h4>每日开区时间: <span v-if="game_details.kaiqu_time">{{ game_details.kaiqu_time }}</span></h4>
                  <!-- <div class="time_lang">
                      <div class="time_card" v-for="c in cards" :key="c.id">
                        <h5 class="time_font">{{c.name}}</h5>
                        <span class="time_span_font">{{c.time}}</span>
                        <img class="time_bg" v-lazy="img.time">
                      </div>
                    </div> -->
                </div>
                <div class="banner_box">
                  <div class="b_left">
                    <div class="banner_title">
                      <div class="title_box">
                        <h6>游戏攻略</h6>
                        <span class="lang_point" :title="name">{{ name }}</span>
                      </div>
                      <a class="look_btn look_btn_color look_btn_font" @click="look">点击查看</a>
                    </div>
                    <!-- 背景 -->
                    <img class="b_left_bg" v-lazy="img_url + material.pc_game_xq_gonglue" width="100%">
                  </div>
                  <img class="b_right" v-lazy="img_url + material.pc_game_xq_wallet">
                </div>
              </div>
            </div>
          </div>
          <div class="col-4">
            <GameClass class="gd" :lb_height="isHeihgt"></GameClass>
          </div>
        </div>
      </div>
      <!-- 背景 -->
      <img class="game_details_bg" v-lazy="img_url + material.pc_background" width="100%">
    </div>
    <Footer></Footer>
    <!-- <ReceiveBenefits ref="rec" :id="this.$route.query.id" :flID="this.$route.query.game_id"></ReceiveBenefits> -->
    <ReceiveBenefits ref="rec" :id="this.$route.query.id" :game_name="name" :img="game_src"
      :under_time="game_details.under_time" :is_under="game_details.is_under"></ReceiveBenefits>
    <Login ref="login"></Login>
  </div>
</template>

<script>
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import GameClass from '../components/GameClass.vue'
import Footer from '../components/Footer.vue'
import GameNavigation from '../components/GameNavigation.vue'
import Login from '@/components/Login.vue'
import { getGameDetails, bloue, userAddMap, getEvaluation } from '@/api/http.js'
import ReceiveBenefits from '@/components/ReceiveBenefits.vue'
import { mapState } from "vuex";
export default {
  name: 'GameDetails',
  components: { GameNavigation, Footer, GameClass, Login, ReceiveBenefits },
  data() {
    return {
      game_src: '',
      domain: '',
      name: '',
      game_title: '',
      down: '',
      web: '',
      under_time: '',  //下架时间
      cards: [
        { id: 1, name: '一区', time: '00:10' },
        { id: 2, name: '二区', time: '08:00' },
        { id: 3, name: '三区', time: '12:00' },
        { id: 4, name: '四区', time: '16:10' },
        { id: 5, name: '五区', time: '20:10' },
        { id: 6, name: '六区', time: '22:10' },
      ],
      game_details: [],
      img: {
        time: '/GameDetails/img/time.png',
        banner2: '/GameDetails/img/banner2.png',
        banner3: '/GameDetails/img/banner3.png',
        bg: '/GameDetails/img/bg.jpg'
      },
      isHeihgt: 0,
      txtUrl: '',
      bool: true, //立即参与按钮节流
      flag: true, //点赞按钮节流
    }
  },
  computed: {
    ...mapState([
      'material',
      'userInfo'
    ])
  },
  methods: {
    
    // 点赞接口
    getEvaluation(type) {
      getEvaluation({
        game_id: this.$route.query.id,
        type: type
      }).then((res) => {
        if (res.code == 200) {
          if(type == 1){
            var numElement = document.getElementById("is_zan");
            numElement.classList.add("fade-out");
            setTimeout(function () {
              numElement.classList.remove("fade-out");
            }, 1000);
          }else{
            var numElement = document.getElementById("is_cai");
            numElement.classList.add("fade-out");
            setTimeout(function () {
              numElement.classList.remove("fade-out");
            }, 1000);
          }
          
          this.getGameDetails()
          this.flag = true
          // this.$message({
          //   message: res.msg,
          //   type: 'success',
          //   onClose: () => {
          //     this.flag = true
          //     this.getGameDetails()
          //   },
          //   duration: 1500,
          // })
        }
      }).catch((err) => {
        console.log('捕获异常', err)
        this.$message({
          message: err.msg,
          type: 'warning',
          onClose: () => {
            this.flag = true
          },
          duration: 1500,
        })
      })
    },
    clickZang() {
      if (this.flag) {
        this.flag = false
        if (localStorage.getItem('token')) {
          if (this.game_details.is_zan && this.game_details.is_cai) {
            this.getEvaluation(1)
          } else {
            this.$message({
              message: '您已评价过该游戏！',
              type: 'warning',
              onClose: () => {
                this.flag = true
              },
              duration: 1500,
            })
          }
        } else {
          this.$message({
            message: "您还未登录，请先登录！",
            type: 'warning',
            duration: 1500,
            onClose: () => {
              this.flag = true
              this.$refs.login.open()
            }
          });
        }
      }else{
        this.$message({
          message: '操作频繁，请勿重复操作！',
          type: 'warning',
          onClose: () => {
            this.flag = true
          },
          duration: 1500,
        })
      }

    },
    clickCai() {
      if (this.flag) {
        this.flag = false
        if (localStorage.getItem('token')) {
          if (this.game_details.is_zan && this.game_details.is_cai) {
            this.getEvaluation(2)
          } else {
            this.$message({
              message: '您已评价过该游戏！',
              type: 'warning',
              onClose: () => {
                this.flag = true
              },
              duration: 1500,
            })
          }
        } else {
          this.$message({
            message: "您还未登录，请先登录！",
            type: 'warning',
            duration: 1500,
            onClose: () => {
              this.flag = true
              this.$refs.login.open()
            }
          });
        }
      }else{
        this.$message({
          message: '操作频繁，请勿重复操作！',
          type: 'warning',
          onClose: () => {
            this.flag = true
          },
          duration: 1500,
        })
      }
    },
    // 参与通关方法
    jion() {
      if (localStorage.getItem('token')) {
        this.$confirm('每个用户最多可同时参与三款游戏，是否选择参与该游戏？如需取消请联系客服。', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          this.userAddMap()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消参与'
          });
        });
      } else {
        if(this.bool){
          this.bool = false
          this.$message({
            message: '请先登录后使用该功能！',
            type: 'warning',
            onClose: () => {
              this.$refs.login.open()
              this.bool = true
            },
            duration: 1500,
          })
        }
        
      }
    },
    // 参与通关接口
    userAddMap() {
      if (this.bool) {
        this.bool = false
        userAddMap({
          game_id: this.$route.query.game_id,
        }).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: 'success',
              onClose: () => {
                this.bool = true
                this.getGameDetails()
              },
              duration: 1500,
            })
          }
        }).catch((err) => {
          console.log('捕获异常', err)
          this.$message({
            message: err.msg,
            type: 'warning',
            onClose: () => {
              this.bool = true
            },
            duration: 1500,
          })
        })
      } else {
        this.$message({
          message: '操作过于频繁，请稍后再试！',
          type: 'warning',
          onClose: () => {
            this.bool = true
          },
          duration: 1500,
        })
      }
    },
    divResize(data) {
      this.isHeihgt = data.height
      console.log("游戏详情高度改变", this.isHeihgt)
    },
    //下载游戏链接转换
    downloadFile(url) {
      var newUrl = url.replace('http', 'https')
      window.open(newUrl, '_self');
    },
    look() {
      if (this.game_details.game_introduction) {
        window.open(this.game_details.game_introduction_url, '_blank');
        // window.location.href = this.img_url + this.game_details.game_introduction;
      } else {
        alert('敬请期待！')
      }
      // if (this.game_details.game_introduction) {
      //   bloue(this.game_details.game_introduction).then(res => {
      //     console.log(res);
      //     this.txtUrl = res.data.file_url
      //     axios({
      //       url: '/dpc' + this.txtUrl,
      //       method: 'get',//不写默认get方式,
      //       // responseType: 'blob'
      //     }).then(res => {
      //       console.log(res)
      //     /*   let gl_url = window.URL.createObjectURL(res.data)
      //       window.open(gl_url, '_blank'); */
      //     })
      //   }).catch((err) => {
      //     console.log('捕获异常', err)
      //   })
      // }else {
      //   alert('敬请期待！')
      // }
    },
    // 领取福利
    receiveBenefits(id) {
      if (localStorage.getItem('token')) {
        if (!this.userInfo.user_idcard) {
          this.$message({
            message: "请先实名认证后使用该功能！",
            type: 'warning',
            onClose: () => {
              this.$router.push({ path: "/member/realName" })
              window.location.href = window.location.href
            },
            duration: 1500,
          });
        } else {
          this.$refs.rec.open(id)
          const receive = $('.receive')
          var that = this
          var LayIndex = layer.open({
            type: 1,
            content: receive,
            anim: false,
            fixed: true,
            shade: 0.6,
            title: 0,
            scrollbar: false,
            shadeClose: true,
            isOutAnim: false,
            closeBtn: 0,
            Boolean: true,
            area: [auto, auto],
            success: function (layero, index) {
              layer.style(index, {
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)'
              });
              window.onresize = () => {
                return (() => {
                  layer.style(index, {
                    transform: 'none'
                  });
                })();
              };
              $('.layui-layer-page .layui-layer-content').css("overflow", "visible")
              const lq = $(layero).find('.close');
              // console.log(lq)
              lq.click(function () {
                // 初始化选项
                that.$refs.rec.fun2()
                layer.close(LayIndex)
              })
            }
          });
        }
      } else {
        this.$message({
          message: "请登录后再试！",
          type: 'warning',
          duration: 1500
        })
      }
    },
    async getGameDetails() {
      var g_id = this.$route.query.id
      var uid = this.$route.query.uid
      try {
        const res = await getGameDetails({
          id: g_id,
          uid: uid
        })
        const a = res.data
        if (res.code === 200) {
          this.game_details = res.data
          this.domain = a.domain //域名
          this.game_src = a.game_image //游戏截图
          this.name = a.game_name
          this.game_title = a.game_introduce //副标题
          this.down = a.client_url
          this.web = a.web_url
        } else {

        }
      } catch (err) {
        console.log(err)
      }
    },


  },
  directives: {
    // 自定义指令名称
    resize: {
      // 绑定时调用
      bind(el, binding) {
        let height = ""
        function isResize() {
          // 可根据需求，调整内部代码，利用binding.value返回即可
          const style = document.defaultView.getComputedStyle(el)
          if (height !== style.height) {
            // 此处关键代码，通过此处代码将数据进行返回，从而做到自适应
            binding.value({ height: style.height })
          }
          height = style.height
        }
        // 设置调用函数的延时，间隔过短会消耗过多资源
        el.__vueSetInterval__ = setInterval(isResize, 100)
      },
      unbind(el) {
        clearInterval(el.__vueSetInterval__)
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      window.onresize = () => {
        return (() => {

        })();
      };
      //  仅在整个视图都被重新渲染之后才会运行的代码
      // window.onresize = () => {
      //   return (() => {
      //     setTimeout(() => {
      //       const lb_h = this.$refs.game_content.offsetHeight
      //       console.log(lb_h)
      //       this.isHeihgt = lb_h
      //     }, 500)
      //   })();
      // };
      setTimeout(() => {
        const lb_h = this.$refs.game_content.offsetHeight
        console.log(lb_h)
        this.isHeihgt = lb_h
      }, 800)

    })
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.$nextTick(function () {
        if (from && from.path === '/member/historyGame') {
          console.log('从 member/historyGame 页面过来了');
          vm.receiveBenefits(vm.game_details.game_id)
        } else {
          console.log('不是从 member/historyGame 页面过来的');
        }
      })
    });
  },
  created() {
    this.getGameDetails()
  }
}
</script>

<style scoped>
/* 点赞功能 */
.big_box {
  position: relative;
}

.like {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 37px;
  padding: 7px 19px;
  background: rgb(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.zan {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
}

.zan:nth-child(1) {
  margin-right: 20px;
}

.islike {
  cursor: pointer;
  margin-right: 15px;
}

.like_title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: #FFFFFF;
  margin-right: 5px;
}

.add_num {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #BF4949;
}

#is_zan {
  opacity: 1;
  position: relative;
  top: 0;
  left: 0;
}
#is_cai {
  opacity: 1;
  position: relative;
  top: 0;
  left: 0;
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    top: -60px;
    left: 40px;
    transform: scale(0.5);
    opacity: 0;
  }
}

.fade-out {
  animation: fade-out 1s ease-out;
}

/*  */
.isClick {
  filter: grayscale(100%);
}

.model_under {
  position: absolute;
  right: 0;
  top: 0;
  width: 25%;
}

.gd {
  position: relative;
  z-index: 2;
}

.row>div:nth-of-type(1) {
  padding-right: 6px;
  padding-left: 0;
}

.row>div:nth-last-of-type(1) {
  padding-left: 6px;
  padding-right: 0;
}

.game_details {
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
  padding: 10px 0;
  margin-top: 124px;
  background: #18181a;
}

.game_details_bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* 内容 */
.game_content {
  position: relative;
  z-index: 2;
  background: #FFFFFF;
}

.banner {
  height: 60vh;
}

.content_box {
  padding: 20px 20px 30px;
}

.content_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content_title h4 {
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #353535;
}

.content_title span {
  font-size: 14px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #999999;
}

.content_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.down_btn {
  padding: 10px 20px;
  border-radius: 4px;
  margin-left: 10px;
}

.btn_font {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFFFFF;
  text-decoration: none;
}

.time_box {
  margin-top: 40px;
}

.time_box h4 {
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #333333;
  margin: 0;
}

.time_lang {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.time_card {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.time_bg {
  position: relative;
  top: 0;
  left: 0;
  z-index: -10;
}

.time_card h5 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 28%;
}

.time_card span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 7%;
}

.time_span_font {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8C7753;
}

.time_font {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #8C7753;
  -webkit-text-stroke: 1px #FDECCE;
  background: linear-gradient(0deg, #8C7753 0%, #A27F42 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.banner_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
}

.b_left {
  position: relative;
  display: flex;
  align-items: center;
}

.b_left_bg {
  position: relative;
  top: 0;
  left: 0;
  z-index: -10;
}

.banner_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;
  position: absolute;
}

.title_box h6 {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FFEBCA;
  margin: 0;
}

.title_box span {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #BFA982;
  width: 80%;
}

.look_btn {
  padding: 10px 12px;
  border-radius: 8px;
}

.look_btn_color {
  background: linear-gradient(0deg, #C69A6F, #F8E3C0);
  transition: all 0.5s;
}

.look_btn_color:hover {
  filter: brightness(1.2);
  transition: all 0.5s;
}

.look_btn_font {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #8F451F;
  text-decoration: none;
}

@media (max-width:1400px) {
  .game_details {
    margin-top: 114px;
  }

  .content_title h4 {
    font-size: 16px;
  }

  .content_title span {
    font-size: 12px;
  }

  .btn_font {
    font-size: 12px;
  }

  .time_box h4 {
    font-size: 16px;
  }

  .time_bg {
    width: 90%;
  }

  .time_font {
    font-size: 16px;
  }

  .time_span_font {
    font-size: 12px;
  }

  .time_card h5 {
    top: 30%;
  }

  .time_card span {
    bottom: 8%;
  }

  .b_left {
    margin-right: 10px;
  }

  .b_right {
    width: 49%;
  }

  .title_box h6 {
    font-size: 16px;
  }

  .title_box span {
    font-size: 12px
  }

  .look_btn_font {
    font-size: 12px;
  }

  .look_btn {
    padding: 5px 10px;
  }

  .time_box {
    margin-top: 30px;
  }

  .banner_box {
    margin-top: 30px;
  }
}

@media (max-width:1400px) {
  .down_btn[data-v-e6d15a08] {
    padding: 5px 10px;
  }

  .title_box h6[data-v-e6d15a08] {
    font-size: 14px;
  }

  .banner_title {
    padding: 0 10px;
  }
}

@media (min-width: 1700px) {
  .container {
    max-width: 1310px !important;
  }

}</style>