import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap';
// 引入路由
import router from './router';
import VueRouter from 'vue-router'

import store from './store'

// 全局引入二维码生成
import QRCode from 'qrcodejs2';

// 引入图片懒加载插件
import atm from '../public/header/img/ljz.png';
//引入插件
import VueLazyload from 'vue-lazyload' 
// 引入全局login
import Login from './components/Login.vue'
import CNZZ from '@/store/cnzz'

// 引入设备唯一标识
import FingerprintJS from '@fingerprintjs/fingerprintjs'

// 引入哨兵
import * as Sentry from "@sentry/vue";

import VueLuckyCanvas from '@lucky-canvas/vue'


Vue.use(VueLuckyCanvas)

// 局部引入elementUI
// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import elementComponents from '@/components/element.js'
Vue.prototype.img_url= 'https://api.91cq.com/media/public/image?code='
// Vue.prototype.img_url= 'https://betaoa.3pk.com/media/public/image?code='
// Vue.prototype.img_url= 'http://www.test91cq.com/media/public/image?code='
// Vue.prototype.img_url= 'http://112.5.90.38:18005/media/public/image?code='

Vue.config.productionTip = false

// 初始化哨兵
try{
  Sentry.init({
    Vue,
    dsn: "https://72ae7825a522b10b95f1c87c0109d871@log.endpoint.yh66.vip/3",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs:false
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  
}catch(err){
  console.log('哨兵异常',err)
}



//注册插件
Vue.use(VueLazyload, { // 添加自定义选项
  preLoad:1.3,
  error: '', // 加载错误时候的图片
  // loading: atm, // 加载中的图片
  attempt: 1,
  listenEvents: [
        'scroll',
        'wheel',
        'mousewheel',
        'resize',
        'animationend',
        'transitionend',
        'touchmove',
    ],
})　

Vue.component('Login',Login)
Vue.use(VueRouter)
Vue.prototype.$qrCode = QRCode;
// 局部引入element ui 的message组件
import {Message,MessageBox } from "element-ui";
Vue.component(Message.name,Message)
Vue.component(MessageBox.name,MessageBox)
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
//  cancelToken中的cancel函数
Vue.$httpRequestList=[];
Vue.prototype.$czc = CNZZ //声明cnzz统计代码
Vue.prototype.$finger = FingerprintJS //申明指纹库


new Vue({
  render: h => h(App),
  store,
  router,
  beforeCreate() {
		Vue.prototype.$bus = this
	}
}).$mount('#app')

