// 该文件专门用于创建整个应用的路由器
import VueRouter from 'vue-router'
import Home from '../pages/Home.vue'
import Member from '../pages/Member.vue'
import PersonalCenter from '../pages/PersonalCenter.vue'
import WalletRecharge from '../pages/WalletRecharge.vue'
import MembershipLevel from '../pages/MembershipLevel.vue'
import GamesPlayed from '../pages/GamesPlayed.vue'
import EchargeRecord from '../pages/EchargeRecord.vue'
import WalletBalanceRecord from '../pages/WalletBalanceRecord.vue'
import GiftBenefitRecord from '../pages/GiftBenefitRecord.vue'
import RedeemGameRecords from '../pages/RedeemGameRecords.vue'
import WelfareGift from '../pages/WelfareGift.vue'
import News from '../pages/News.vue'
import NewDetail from '../pages/NewDetail.vue'
import GameDetails from '../pages/GameDetails.vue'
import Help from '../pages/Help.vue'
import RealName from '../pages/RealName.vue'
import PhoneBinding from '../pages/PhoneBinding.vue'
import PasswordModification from '../pages/PasswordModification.vue'
import Registration from '../pages/Registration.vue'
import ForgotPassword from '../pages/ForgotPassword.vue'
import MemberExchangeRecord from '../pages/MemberExchangeRecord.vue'
import BeginnerTutorial from '../pages/BeginnerTutorial.vue';
import ContinuousRecharge from '../pages/activity/ContinuousRecharge.vue';
import Turntable3 from '../pages/activity/Turntable3.vue'
import Turntable2 from '../pages/activity/Turntable2.vue';
import Turntable from '../pages/activity/Turntable.vue';
import PointsMall from '../pages/PointsMall.vue'
import Bank from '../pages/Bank.vue'
import CreateUer from '../pages/CreateUer.vue'
import Atlas from '../pages/Atlas.vue'
import wsnham from '../pages/wsnham.vue'
import ProductDetalis from '../pages/ProductDetalis.vue'
import Rebate from '../pages/activity/Rebate.vue'
import MonthlyCardRecord from '../pages/MonthlyCardRecord.vue'
import Charts from '../pages/Charts.vue'
import Egg from '../pages/activity/Egg.vue'
import NewYear from '../pages/activity/NewYear.vue'
import MemberMouthRecord from '../pages/MemberMouthRecord.vue'
import LabourDay from '../pages/activity/LabourDay.vue'
import RemoveGame from '../pages/RemoveGame.vue'
import HistoryGame from '../pages/HistoryGame.vue'
import DragonBoat from '../pages/activity/DragonBoat.vue'
import HeatWave from '../pages/activity/HeatWave.vue'
import SquaredIndex from '../pages/activity/SquaredIndex.vue'
import JulyEnd from '../pages/activity/JulyEnd.vue'

// 解决：Uncaught (in promise) NavigationDuplicated; 
let originalPush = VueRouter.prototype.push; // 先保存一份 VueRouter.prototype.push方法
let originalRepace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location) {
    // call(); 篡改上下文    catch(); 捕获异常 **** 
    return originalPush.call(this, location).catch(e => e);
}
VueRouter.prototype.replace = function replace(location) {
    return originalRepace.call(this, location).catch(e => e);

}

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '*',
            component: Home,
            // meta:{title:'89cq'}
        },
        {
            path: '/home',
            component: Home,
        },
        {
            path: '/wpath',
            component: Home,
        },
        // 待下架转区
        {
            path: '/removegame',
            component: RemoveGame,
        },
        {
            path: '/member',
            component: Member,
            meta: { title: '会员中心' },
            children: [
                {
                    path: 'personal',
                    component: PersonalCenter,
                    meta: { title: '个人中心',isLogin: true }
                },
                {
                    path: 'wallet',
                    component: WalletRecharge,
                    meta: { title: '会员平台充值', isLogin: false },
                },
                {
                    path: 'level',
                    component: MembershipLevel,
                    meta: { title: '会员等级',isLogin: true }
                },
                {
                    path: 'played',
                    component: GamesPlayed,
                    meta: { title: '玩过的游戏', isLogin: true },
                },
                {
                    path: 'echarge',
                    component: EchargeRecord,
                    meta: { title: '充值记录', isLogin: true },
                },
                {
                    path: 'walletBalance',
                    component: WalletBalanceRecord,
                    meta: { title: '账户余额记录', isLogin: true },
                },
                {
                    path: 'giftBenefit',
                    component: GiftBenefitRecord,
                    meta: { title: '赠送福利记录', isLogin: true },
                },
                {
                    path: 'redeemGame',
                    component: RedeemGameRecords,
                    meta: { title: '兑换游戏记录', isLogin: true },
                },
                {
                    path: 'realName',
                    component: RealName,
                    meta: { title: '实名认证', isLogin: true },
                },
                {
                    path: 'phoneBinding',
                    component: PhoneBinding,
                    meta: { title: '手机绑定', isLogin: true },
                },
                {
                    path: 'passwordModification',
                    component: PasswordModification,
                    meta: { title: '密码修改', isLogin: true },
                },

                {
                    path: 'memberExchangeRecord',
                    component: MemberExchangeRecord,
                    meta: { title: '会员兑换记录', isLogin: true },
                },
                {
                    path: 'createUer',
                    component: CreateUer,
                    meta: { title: '绑定账号', isLogin: true },
                },
                {
                    path: 'monthlyCardRecord',
                    component: MonthlyCardRecord,
                    meta: { title: '月卡记录', isLogin: true },
                },
                {
                    path: 'memberMouthRecord',
                    component: MemberMouthRecord,
                    meta: { title: '包服卡兑换记录', isLogin: true },
                },
                {
                    path: 'historyGame',
                    component: HistoryGame,
                    meta: { title: '历史版本', isLogin: true },
                },
            ]
        },
        {
            path: '/forgot',
            component: ForgotPassword,
            meta: { title: '忘记密码' },
        },
        {
            path: '/welfareGift',
            component: WelfareGift,
            meta: { title: '福利礼包' },
        },
        {
            path: '/information',
            component: News,
            meta: { title: '新闻咨询' },
        },
        {
            path: '/newDetail',
            component: NewDetail,
            meta: { title: '新闻详情' },
        },
        {
            name: 'gameDetails',
            path: '/gameDetails',
            component: GameDetails,
            meta: { title: '游戏详情' },
            // props($route){
            //     return {id:$route.query.id}
            // }
        },
        // {
        //     path: '/atlas',
        //     component: Atlas,
        //     meta: { title: '通关成就',isLogin: true },
        // },
        {
            path: '/helps',
            component: Help,
            meta: { title: '帮助中心' },
        },
        {
            name: 'registration',
            path: '/register',
            component: Registration,
            meta: { title: '账号注册', isLogin: false },
        },
        {
            path: '/tutorial',
            component: BeginnerTutorial,
            meta: { title: '新手教程' }
        },
        {
            path: '/continuousrecharge',
            component: ContinuousRecharge,
            meta: { title: '充值活动' },
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('token')) {
                    next()
                } else {
                    alert('请先登录')
                }
            }
        },
        {
            path: '/Turntable',
            component: Turntable,
            meta: { title: '大转盘' },
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('token')) {
                    next()
                } else {
                    alert('请先登录')
                }
            }
        },
        {
            path: '/Turntable_National',
            component: Turntable3,
            meta: { title: '91cq中秋国庆双节同庆' },
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('token')) {
                    next()
                } else {
                    alert('请先登录')
                }
            }
        },
        {
            path: '/Rebate',
            component: Rebate,
            meta: { title: '超级返利活动' },
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('token')) {
                    next()
                } else {
                    alert('请先登录')
                }
            }
        },
        {
            path: '/Egg',
            component: Egg,
            meta: { title: '跨年迎双旦福蛋乐翻天' },
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('token')) {
                    next()
                } else {
                    alert('请先登录')
                }
            }
        },
        {
            path: '/NewYear',
            component: NewYear,
            meta: { title: '金龙呈祥福满门，火凤翔舞喜迎春' },
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('token')) {
                    next()
                } else {
                    alert('请先登录')
                }
            }
        },
        {
            path: '/labourDay',
            component: LabourDay,
            meta: { title: '五一劳动节活动' },
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('token')) {
                    next()
                } else {
                    alert('请先登录')
                }
            }
        },
        {
            path: '/dragonBoat',
            component: DragonBoat,
            meta: { title: '端午节活动' },
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('token')) {
                    next()
                } else {
                    alert('请先登录')
                }
            }
        },
        {
            path: '/heatWave',
            component: HeatWave,
            meta: { title: '七月热浪来袭' },
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('token')) {
                    next()
                } else {
                    alert('请先登录')
                }
            }
        },
        {
            path: '/squaredIndex',
            component: SquaredIndex,
            meta: { title: '九宫格活动' },
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('token')) {
                    next()
                } else {
                    alert('请先登录')
                }
            }
        },
        {
            path: '/julyEnd',
            component: JulyEnd,
            meta: { title: '七月末特别福利活动' },
            beforeEnter: (to, from, next) => {
                if (localStorage.getItem('token')) {
                    next()
                } else {
                    alert('请先登录')
                }
            }
        },
        // {
        //     path:'/pointsMall',
        //     component:PointsMall,
        //     meta:{title:'灵符商城',isLogin: false},
        // },
        {
            path:'/bank',
            component:Bank,
            meta:{title:'91CQ会员平台'},
        },
        // 空白页
        {
            path:'/wsnham',
            component:wsnham,
            meta:{title:'91CQ会员平台'},
        },
        {
            path:'/productDetalis',
            component:ProductDetalis,
            meta:{title:'商品详情'},
        },
        {
            path:'/chartsing',
            component:Charts,
            meta:{title:'排行榜'},
        }
    ]
})
// 全局前置路由守卫——每次路由切换前、初始化时被调用
router.beforeEach((to, from, next) => {
     if (to.meta.isLogin) {
        if (localStorage.getItem('token')) {
            console.log('有token,准备跳转')
            const userInfo = JSON.parse(localStorage.getItem('userInfo'))
            console.log('userInfo=',userInfo)
            if(to.path == '/member/realName'){
                console.log('有token，我要去实名，准备判断')
                if(userInfo.user_idcard){
                    alert('已实名认证')
                    router.push({
                        path:'/member'
                    })
                }
                else{
                    console.log('我免费了')
                    next()
                }
            }
            else if(to.path == '/member/phoneBinding'){
                if(userInfo.user_mobile){
                    alert('已完善信息')
                    router.push({
                        path:'/member'
                    })
                }
                else{
                    next()
                }
            }
            else{
                next()
            }
        } else {
            alert('请先登录')
        }
    } 
    else if(to.path === '/member'){
        if(localStorage.getItem('token')){
            next('/member/personal')
        }else{
            alert('请先登录')
        }
    }
    else if(to.path === '/wpath'){
        console.log('dddddddassssssssssssssssssssssss')
        // 获取当前路径的参数部分
        var queryString = window.location.search;
        // 创建一个URLSearchParams对象
        var searchParams = new URLSearchParams(queryString);
        // 获取特定参数的值
        var paramValue = searchParams.get('t');
        if(paramValue){
            localStorage.setItem('w86','1')
            localStorage.setItem('img_png',paramValue)
            next()
        }else{
            next('/')
        }
    }
    // else if(to.path == '/register'){
    //     console.log('我要去跳转页面')
    //     if(localStorage.getItem('token')){
            
    //     }else{

    //     }
    // }
    else{
        next()
    }

})

// 全局后置路由守卫——每次路由切换后、初始化时被调用
router.afterEach((to, form) => {
    // console.log('后置路由守卫')
    document.title = to.meta.title
})

export default router