<template>
  <div>
    <Header3></Header3>
    <Banner2></Banner2>
    <!-- <Ranking></Ranking> -->
    <!-- <textarea name="" id="" cols="30" value="asdasdasdasdasd" rows="10"></textarea> -->
    <GameList2></GameList2>
    <OpenActive id="opactive" @openLogin="openLogin"></OpenActive>
    <ActiveSuspension  v-if="material.pc_index_alert ? material.pc_index_alert.img : false"></ActiveSuspension>
    <ActiveSuspensionLeft v-if="material.pc_index_left ? material.pc_index_left.img : false"></ActiveSuspensionLeft>
    <Age></Age>
    <Report></Report>
    <ReturnTop></ReturnTop>
    <TopEngine></TopEngine>
    <!-- <Inquiry></Inquiry> -->
    <Footer2></Footer2>
    <Login class="login" ref="login"></Login>
  </div>
</template>

<script>
// import Inquiry from '../components/Inquiry.vue'
import Header3 from '../components/Header3.vue';
import Footer2 from '../components/Footer2.vue';
import Banner2 from '../components/Banner2.vue';
import Ranking from '../components/Ranking.vue';
import GameList2 from '../components/GameList2.vue';
import ActiveSuspension from '../components/ActiveSuspension.vue'
import ActiveSuspensionLeft from '../components/ActiveSuspensionLeft.vue'
import Age from '../components/Age.vue'
import Report from '../components/Report.vue'
import OpenActive from '../components/OpenActive.vue'
import ReturnTop from '../components/ReturnTop.vue'
import TopEngine from '../components/TopEngine.vue'
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import { mapState } from 'vuex';
    export default {
        name:'Home',
        components:{
            Header3,
            Footer2,
            Banner2,
            Ranking,
            GameList2,
            ActiveSuspension,
            ActiveSuspensionLeft,
            Age,
            Report,
            OpenActive,
            ReturnTop,
            // Inquiry,
            TopEngine
        },
        data() {
          return {
          }
        },
        computed:{
          ...mapState([
            'getClient',
            'material'
          ])
        },
        methods:{
          openLogin(){
            this.$refs.login.open()
          },
          // 询问弹窗方法
          // inquiry(){
          //     var inquiry = $('.inquiry')
          //     var that = this
          //     var LayIndex =  layer.open({
          //         type: 1,  //可传入的值有：0（信息框，默认）1（页面层）2（iframe层）3（加载层）4（tips层）
          //         title: ['通知', 'font-size:18px;font-weight: bolder;text-align: center;padding:0;background:#fff !importent;'],//数组第二项可以写任意css样式；如果你不想显示标题栏，你可以title: false
          //         content:inquiry ,
          //         shade: 0.6,
          //         fixed: true,
          //         scrollbar: false,
          //         shadeClose: false,
          //         isOutAnim: false,
          //         area:['400px','180px'],
          //         closeBtn:0,
          //         scrollbar: false ,//屏蔽浏览器滚动条
          //         success: function (layero, index) {
          //             const lq = $(layero).find('.inquiry_know');
          //             lq.click(function () {
          //                 layer.close(LayIndex)
          //             })
          //             let countdown = 5;
          //             const timer = setInterval(() => {
          //             lq.html('知道了(' + countdown + 's)')
          //             countdown--;
          //             if (countdown < 0) {
          //                 clearInterval(timer);
          //                 layer.close(LayIndex)
          //             }
          //             }, 1000); 
          //         },
          //         end:function(){
          //           localStorage.setItem('inquiry_show', 0)
          //         }
              
          //     });
          // },
        },
        mounted(){
            // 这里放置只需要执行一次的逻辑
            // if(localStorage.getItem('inquiry_show') == 1 && localStorage.getItem('token')){
            //   this.inquiry()
            // }
        },
        created(){
          if(localStorage.getItem('w86') == 1 && localStorage.getItem('img_png')){
            this.$router.push({
                  path:'/'
              })
          }
        }
       
        
    }
</script>

<style scoped>

</style>