<template>
    <div class="bag_main">
        <div class="bag_title">
            <div class="cz_class">
                <div class="cz_item flex_box">
                    <h4 class="font_color" @click="cz_id = 1" :class="{ 'cz_color': cz_id == 1 }">会员平台充值</h4>
                    
                    <img v-show="cz_id == 1" class="btn_bg" src="/member/img/btn_bg.png">
                </div>
                <!-- <div class="cz_item flex_box">
                    <h4 class="font_color" @click="cz_id = 2" :class="{ 'cz_color': cz_id == 2 }">购买月卡</h4>
                    <img v-show="cz_id == 2" class="btn_bg" src="/member/img/btn_bg.png">
                </div> -->
                <div class="cz_item flex_box" v-if="!userInfo.m_user_mouth">
                    <h4 class="font_color" @click="cz_id = 3" :class="{ 'cz_color': cz_id == 3 }">购买包服卡</h4>
                    <img v-show="cz_id == 3" class="btn_bg" src="/member/img/btn_bg.png">
                </div>
            </div>
            <div class="tip">
                <div class="tip_icon flex_box font_color">?</div>
                <span class="font_color">如遇充值问题，请联系任意平台客服</span>
            </div>
        </div>
        <div class="bag_box">
            <div v-show="cz_id == 1">
                <!-- 广告banner -->
                <div class="cz_ad flex_box" v-if="pc_image">
                    <a @click="toActive"><img class="ad_banner" :src="pc_image" ></a>
                </div>
                <!--  -->
                <div class="bag_content">
                    <div class="content_left">
                        <span class="content_left_font" :class="{'my_mg':pc_image}">充值金额：</span>
                        <div class="money_items">
                            <div class="money" ref="my" @click="hitMoney(index)" v-for="(m, index) in feed_list"
                                :key="index" :value="m.value" :real_value="m.real_value">
                                <div class="jb_box">
                                    <span>{{ times }}倍</span>
                                    <img class="jb_bg" src="/WalletRecharge/img/jb.png">
                                </div>
                                <div class="card_content">
                                    <span class="card_title">可获得</span>
                                    <h5 class="host_num">{{ Math.ceil(m.value_sum) }}<span>储值</span></h5>
                                    <span class="send_title">包含额外赠送：{{ Math.ceil(m.value_percentage) }}</span>
                                    <div class="flex_box new_spade_money" v-if="m.show_value == 1">
                                        <div class="old_price">
                                            <div class="huaheng"></div>
                                            <span class="old_price_num">原价:{{ Math.ceil(m.value)}}</span>
                                        </div>
                                        <span class="new_price_num">优惠价:{{ m.real_value }}</span>
                                        <img class="xsth" src="/WalletRecharge/img/xsth.png">
                                    </div>
                                    <span v-else class="spade_money money_size">支付{{ Math.ceil(m.value) }}</span>
                                </div>
                                <!-- 背景 -->
                                <img v-if="isMoney === index" class="card_bg" src="/WalletRecharge/img/after.png">
                                <img v-else class="card_bg" src="/WalletRecharge/img/before.png">
                            </div>
                            <div class="money" ref="my" @click.stop="focusInput">
                                <div class="jb_box">
                                    <span>{{ times }}倍</span>
                                    <img class="jb_bg" src="/WalletRecharge/img/jb.png">
                                </div>
                                <div class="card_content">
                                    <span class="card_title">可获得</span>
                                    <h5 class="host_num">{{ Math.ceil(receiptNum) }}<span>储值</span></h5>
                                    <span class="send_title">包含额外赠送：{{ lvNumShow }}</span>
                                    <input class=" money_size form_qita" maxlength="4"
                                        oninput="value=value.replace(/[^\d]/g,'')" ref="custom_money" type="text"
                                        v-model.number="ref_money" placeholder="请输入金额" v-on:focus="isFocused = true"
                                        v-on:blur="isFocused = false; isMoney = 99" @focus="ref_focus()" @click.stop>
                                </div>
                                <!-- 背景 -->
                                <img v-if="isFocused || isMoney == 99" class="card_bg" src="/WalletRecharge/img/after.png">
                                <img v-else class="card_bg" src="/WalletRecharge/img/before.png">
                            </div>
                        </div>

                        <div class="my_mg qq">
                            <label class="content_left_font mb-2">联系QQ：</label>
                            <input type="text" maxlength="20" oninput="value=value.replace(/[^\d]/g,'')" class="qq_input"
                                v-model.number="contact">
                        </div>
                        <div class="payment">
                            <span class="content_left_font">支付方式：<a style="font-size: 12px; color: red;">提示：您额外支付的风控金为第三方支付收取，本站已经在您充值成功后额外赠送50平台币！</a></span>
                            <div class="flex_box mt-2">
                                <a class="method content_left_font pay_hover flex_box"
                                    :class="{ 'hit_pay': isMethod === p.value }" @click="isMethod = p.value"
                                    v-for="p in pay_list" :key="p.value">
                                    <img :src="p.image" class="method_img">
                                    {{ p.label }}
                                    <img class="qr" :class="{ 'btn_active': isMethod === p.value }"
                                        src="/WalletRecharge/img/gou2.png">
                                </a>
                            </div>
                        </div>
                        <div class="money_unifo">
                            <span class=" content_left_font my_mg lv_m">充值金额：<span class="number_color">{{ moneyValue
                            }}</span></span>
                            <span class=" content_left_font my_mg lv_m">实际金额：<span class="number_color">{{ real_value
                            }}</span></span>
                            <span class=" content_left_font my_mg lv_m">储值到账：<span class="number_color">{{
                                Math.ceil(moneyValue * times) }}</span></span>
                            <div class="lv_send my_mg">
                                <span class="content_left_font lv_m">储值加送：<span class="number_color">+{{ lvNumShow
                                }}</span></span>
                                <!-- <div class="add_send">
                            <span class="content_left_font">V0等级加送0% </span>
                            <a class="lv_hop">提升等级></a>
                        </div> -->
                            </div>
                            <div class=" receipt my_mg">
                                <h4 class="content_left_font lv_m">实际到账：<span class="number_color">{{ Math.ceil(receiptNum)
                                }}</span></h4>
                                <!-- <div class="add_send">
                            <h4 class="content_left_font">支付成功可额外获得<span class="number_color">{{ addSendNum }}</span>积分</h4>
                            </div> -->
                            </div>
                        </div>
                        <div class="money_unifo">
                            <a  class="submit_btn btn_color btn_font my_mg" @click="pay()">立即提交</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div v-show="cz_id == 2">
                <div class="">
                    <div class="card_box" v-if="!userInfo.m_user_card">
                        <span class="content_left_font ">月卡:</span>
                        <div class="card_item mt-2">
                            <div class="row">
                                <div class="col-3" v-for="card in card_data" :key="card.id"
                                    @click="is_card = card.id; selectCard(card)">
                                    <div class="card_index">
                                        <img class="card_img" v-if="is_card == card.id" :src="img_url + card.card_img_fan"
                                            width="100%">
                                        <img class="card_img" v-else :src="img_url + card.card_img" width="100%">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my_card" v-else>
                        <div class="flex_box">
                            <span class="content_left_font ">月卡奖励</span>
                            <span class="become_time">有效期至：{{ become_time }}</span>
                        </div>
                        <div class="my_card_box">
                            <img class="my_card_img" v-lazy="img_url + ueser_cards.card_img" >
                            <div class=" ykfl_box">
                                <div class="ykfl_content">
                                    <div class="ykfl_title">
                                        <h4>月卡福利</h4>
                                        <span>MONTHLY CARD</span>
                                    </div>
                                    <div class="yk_num">
                                        <span class="yk_tip">{{ my_day_receive }}</span>
                                        <img class="ds_bg" src="/WalletRecharge/img/ds.png">
                                    </div>
                                    <div class="yk_title">每日可领取月卡点</div>
                                    <div class="yk_sm">
                                        <span>月卡规则：</span>
                                        <span class="suoming">·月卡购买后，持续{{my_card_time}}天享受月卡权益，须到期才可以更改月卡档位。每张月卡所对应的奖励不同.</span>
                                    </div>
                                </div>
                                <img class="people" src="/WalletRecharge/img/people.png">
                                <img class="yk_bg" src="/WalletRecharge/img/ykfl.png">
                            </div>

                        </div>
                    </div>

                    <div class="payment" v-if="!userInfo.m_user_card">
                        <span class="content_left_font">支付方式：</span>
                        <div class="flex_box mt-2">
                            <a class="method content_left_font pay_hover flex_box"
                                :class="{ 'hit_pay': isMethod === p.value }" @click="isMethod = p.value"
                                v-for="p in pay_list" :key="p.value">
                                <img :src="p.image" class="method_img">
                                {{ p.label }}
                                <img class="qr" :class="{ 'btn_active': isMethod === p.value }"
                                    src="/WalletRecharge/img/gou2.png">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="money_unifo" v-if="!userInfo.m_user_card">
                    <span class=" content_left_font my_mg lv_m">月卡售价：<span class="number_color">{{ card_price
                    }}</span>元</span>
                    <span class=" content_left_font my_mg lv_m">每日可领取月卡点：<span class="number_color">{{ day_receive
                    }}</span></span>
                    <div class="lv_send my_mg">
                        <span class="content_left_font lv_m">月卡有效期：<span class="number_color">{{ expires_time
                        }}</span>天</span>
                    </div>
                </div>
                <div class="money_unifo">
                    <a v-if="!userInfo.m_user_card" class="submit_btn btn_color btn_font my_mg" @click="cardPay()">立即提交</a>
                    <a v-else-if="is_sigin" class="lq_btn btn_color btn_font my_mg" @click="SignIn">立即领取</a>
                    <a v-else class="lq_btn btn_font my_mg isClick">已领取</a>
                </div>
            </div> -->
            <div v-show="cz_id == 3" v-if="!userInfo.m_user_mouth">
                <div class="">
                    <div class="card_box" >
                        <span class="content_left_font ">包服卡:</span>
                        <div class="card_item mt-2">
                            <div class="row">
                                <div class="col-3" v-for="card in mouth_data" :key="card.id"
                                    @click="is_card = card.id; mouth_price = card.price">
                                    <div class="card_index">
                                        <img class="card_img" v-if="is_card == card.id" :src="card.card_img_fan"
                                            width="100%">
                                        <img class="card_img" v-else :src="card.card_img" width="100%">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="payment" v-if="!userInfo.m_user_card">
                        <span class="content_left_font">支付方式：</span>
                        <div class="flex_box mt-2">
                            <a class="method content_left_font pay_hover flex_box"
                                :class="{ 'hit_pay': isMethod === p.value }" @click="isMethod = p.value"
                                v-for="p in pay_list" :key="p.value">
                                <img :src="p.image" class="method_img">
                                {{ p.label }}
                                <img class="qr" :class="{ 'btn_active': isMethod === p.value }"
                                    src="/WalletRecharge/img/gou2.png">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="money_unifo" v-if="!userInfo.m_user_card">
                    <span class=" content_left_font my_mg lv_m">包服卡售价：<span class="number_color">{{ mouth_price
                    }}</span>元</span>
                </div>
                <div class="money_unifo">
                    <a v-if="!userInfo.m_user_card" class="submit_btn btn_color btn_font my_mg" @click="mouthPay()">立即提交</a>
                </div>
            </div>
            <Login ref="login"></Login>
        </div>
        <!-- 活动悬浮窗 -->
        <!-- <ActiveSuspension  v-if="material.pc_index_alert ? material.pc_index_alert.img : false"></ActiveSuspension> -->
    </div>
</template>
  
<script>
import { auto } from "@popperjs/core";
import layer from "layui-layer";
import Login from '../components/Login.vue'
import ActiveSuspension from '../components/ActiveSuspension.vue'
import { getPaymentAvailableList, initiatePayment, verifyOrderStatus, getCardList, createCardCharge, getUserCard,toCardSigin,getCardSigin,selectMyUserCard,getRechargeAmount,getMouthList,createMouthCharge } from '@/api/http.js'
import { mapState } from 'vuex';
export default {
    name: 'WalletRecharge',
    components: { Login,ActiveSuspension },
    data() {
        return {
            feed_list: '', //充值项数组
            pay_list: '',    //支付方式项
            isMoney: '', //选择状态
            ref_money: '', //自定义金额
            real_value:0,//实际金额
            moneyValue: 0, //充值金额
            lvNum: 0, //储值加送
            addSendNum: 0, //等级加送
            isMethod: '',    //支付选择
            contact: '', //联系方式
            max_price: '',   //最大充值金额
            min_price: '',    //最小充值金额
            times: '', //倍数
            bool: true, //判断发起订单请求（防止连续发起）
            timer: '',
            pc_image: false, //充值界面banner
            pc_jump_url: '',
            tab: '_self',
            isFocused: false,
            card_data: [], //卡牌列表
            mouth_data: [], //包服卡列表
            is_card: '', //卡牌选择
            card_price: 0, //卡牌售价
            mouth_price:0, //包服卡售价
            day_receive: 0, //每日可领取积分
            expires_time: 0, //有效期
            card_id: '',//当前选择的卡包ID
            czc_type: '', //如果为1时表示当前订单为平台充值，为2表示当前订单为月卡
            ueser_cards: [], //用户月卡信息
            become_time: '', //用户当前月卡到期时间
            my_day_receive:0, //当前拥有月卡每日可领取
            flag: true,//签到按钮点击状态
            is_sigin: '',//是否可以签到
            my_card_time:0, //当前拥有月卡天数
            my_card_open:'', //用户是否有月卡
            amount_timer:'',//获取实际金额请求定时器
        }
    },
    watch: {
        ref_money: {
            // immediate: true, // 立即执行
            deep: true,
            handler(nval, oval) {
                if (nval) {
                    this.isMoney = ''
                    this.real_value = 0
                    this.moneyValue = this.ref_money
                    clearTimeout(this.amount_timer); // 清除上一个定时器
                    this.amount_timer = setTimeout(() => {
                        this.getRechargeAmount()
                    }, 1000); 
                } else {
                    this.moneyValue = 0
                }
                // 当input框有值，又去选中其他金额，让充值金额变为选中的金额
                if (this.isMoney) {

                    if (this.$refs.my[this.isMoney]) {
                        this.moneyValue = this.$refs.my[this.isMoney].getAttribute('value')
                    }
                }
                else if (this.isMoney == 0) {
                    if (this.$refs.my[this.isMoney]) {
                        this.moneyValue = this.$refs.my[this.isMoney].getAttribute('value')
                    }
                }
                // console.log(oval + '变为' + nval)
                this.feed_list.some((item, index) => {
                    if (nval * 1 < item.value) {
                        let ab = this.feed_list[index - 1] ? this.feed_list[index - 1].percentage : this.feed_list[index].percentage
                        this.lvNum = nval * 1 * ab * this.times
                        return true
                    } else if (nval * 1 >= this.feed_list[this.feed_list.length - 1].value) {
                        this.lvNum = nval * 1 * this.feed_list[this.feed_list.length - 1].percentage * this.times
                    }
                })
            }
        },
        lvNum: {
            immediate: true, // 立即执行
            deep: true,
            handler(nval, oval) {
                this.lvNum = Number(nval).toFixed(2)
            }
        },
        isMoney: {
            deep: true,
            handler(nval, oval) {
                if (nval && nval != 99) {
                    this.lvNum = this.cfa(this.moneyValue, this.feed_list[nval].percentage)
                }
            }
        }

    },
    computed: {
        ...mapState([
            'getClient',
            'userInfo',
            'material'
        ]),
        sigin_eg: {
            get() {
                return this.$store.state.sigin_eg
            },
            set(val) {
                return this.$store.state.sigin_eg = val
            }
        },
        cz_id: {
            get() {
                return this.$store.state.cz_id
            },
            set(val) {
                return this.$store.state.cz_id = val
            }
        },
        lvNumShow() {
            var val = Math.ceil(this.receiptNum) - Math.ceil(this.moneyValue * this.times)
            return val
        },
        receiptNum: {
            get() {
                var result = eval((this.moneyValue * 1)) * this.times + this.lvNum * 1
                return Number(result).toFixed(2)
            },
            set(val) {
                return val
            }

        },
        pay_ewm: {
            get() {
                return this.$store.state.pay_ewm
            },
            set(val) {
                return this.$store.state.pay_ewm = val
            }
        },
        pay_money: {
            get() {
                return this.$store.state.pay_money
            },
            set(val) {
                return this.$store.state.pay_money = val
            }
        },
        pay_type: {
            get() {
                return this.$store.state.pay_type
            },
            set(val) {
                return this.$store.state.pay_type = val
            }
        },
        orderID: {
            get() {
                return this.$store.state.orderID
            },
            set(val) {
                return this.$store.state.orderID = val
            }
        },
    },
    methods: {
         // 获取包服卡列表接口
         getMouthList(){
            getMouthList().then((res)=>{
                if(res.code == 200){
                    this.mouth_data = res.data
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500
                });
            })
        },
        //获取实际充值金额接口
        getRechargeAmount(){
            getRechargeAmount({
                money:this.ref_money
            }).then((res)=>{
                if(res.code == 200){
                    this.real_value = res.data.real_money
                }
            }).catch((err)=>{
                console.log("捕获异常", err)
            })
        },
        // 查询用户是否有月卡
        selectMyUserCard(){
            selectMyUserCard().then((res)=>{
                if(res.code == 200){
                    this.my_card_open = res.msg
                    if(res.msg){
                        this.getCardSigin()
                        this.getUserCard()
                    }
                }
            }).catch((err)=>{
                console.log("捕获异常", err)
            })
        },
        //签到状态查询接口
        getCardSigin() {
            getCardSigin().then((res) => {
                if (res.code === 200) {
                    console.log(res)
                    this.is_sigin = res.data.is_sigin
                }
            }).catch((err) => {
                console.log("捕获异常", err)
            })
        },
        // 获取签到奖励MSG接口
        toCardSigin() {
            toCardSigin().then((res) => {
                if (res.code === 200) {
                    this.sigin_eg = res.msg
                    console.log(res);
                    this.tcSigin()
                }
            }).catch((err) => {
                console.log("捕获异常", err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500,
                    onClose: () => {
                        this.flag = true
                    }
                })
            })
        },
        toPayCard(){
            this.cz_id = 2
            this.$router.push({
                path:'/member/wallet'
            })
        },
        // 弹窗方法
        tcSigin() {
            var that = this
            var sign_tc = $('.sign_tc')
            var LayIndex = layer.open({
                type: 1,
                content: sign_tc,
                anim: false,
                fixed: true,
                shade: 0.6,
                title: 0,
                scrollbar: true,
                shadeClose: false,
                closeBtn: 0,
                isOutAnim: false,
                area: [auto, auto],
                success: function (layero) {
                    const lq = $(layero).find('.sign_btn');
                    lq.click(function () {
                        layer.close(LayIndex)
                        location.reload()
                        // that.getUserSigin()
                    })
                }
            });
        },
        SignIn() {
            if (this.userInfo.day_card_integral) {
                if (this.flag && this.is_sigin == true) {
                    this.flag = false
                    this.toCardSigin()
                } else {
                    this.flag = false
                    if (this.is_sigin == false) {
                        this.$message({
                            message: '今日已经领取！',
                            type: 'warning',
                            duration: 1500,
                            onClose: () => {
                                this.flag = true
                            }
                        })
                    } else {
                        this.$message({
                            message: '请勿重复提交，请稍后再试~',
                            type: 'warning',
                            duration: 1500,
                            onClose: () => {
                                this.flag = true
                            }
                        })
                    }

                }
            }else{
                this.$confirm('当前账号未购买月卡或月卡已过期，是否跳转至月卡购买页面！', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.toPayCard()
                }).catch(() => {
                });
            }

        },
        // 获取用户卡牌信息接口
        getUserCard() {
            getUserCard().then((res) => {
                if (res.code == 200) {
                    this.become_time = res.data[0].expires_time
                    this.ueser_cards = res.data[0].data
                    this.my_day_receive = res.data[0].data.day_receive
                    this.my_card_time = res.data[0].data.expires_time
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500
                });
            })
        },
        // 选择卡包
        selectCard(card) {
            this.card_price = Number(card.price).toFixed(0)
            this.day_receive = card.day_receive
            this.expires_time = card.expires_time
            this.card_id = card.id
        },
        // 月卡列表接口
        getCardList() {
            getCardList().then((res) => {
                if (res.code == 200) {
                    this.card_data = res.data
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500
                });
            })
        },
        focusInput() {
            this.$refs.custom_money.focus();
        },
        //直接点击输入框初始化
        ref_focus(){
            this.isMoney = 99
            this.moneyValue = 0
            this.lvNum = 0
            this.receiptNum = 0
            this.ref_money = ''
            this.real_value = 0
        },
        toActive() {
            if (this.pc_jump_url) {
                if (localStorage.getItem('token')) {
                    this.funRouter()
                } else {
                    this.$refs.login.open()
                }
            }
            this.$czc('pc充值页面横幅', '点击', '访问活动页面')
            // this.$czc('测试埋点', '点击', '访问')
        },
        funRouter() {
            var routeUrl = this.$router.resolve({
                path: this.pc_jump_url,
            })
            window.open(this.pc_jump_url.includes('http') ? this.url : routeUrl.href, '_blank');
        },
        hitMoney(index) {
            this.isMoney = index
            // console.log(index)
            this.ref_money = ''
            this.real_value = this.$refs.my[this.isMoney].getAttribute('real_value')
            this.moneyValue = this.$refs.my[index].getAttribute('value')  //获取上面选择的元素的value属性值
            this.lvNum = this.cfa(this.moneyValue, this.feed_list[index].percentage)
        },
        cfa(arg1, arg2) {
            var m = 0;
            var s1 = arg1.toString();
            var s2 = arg2.toString();
            try {
                m += s1.split(".")[1].length;
            } catch (e) { }
            try {
                m += s2.split(".")[1].length;
            } catch (e) { }

            return Number(s1.replace(".", "")) * Number(s2.replace(".", "") * this.times) / Math.pow(10, m);
        },
        // 获取支付可用列表
        getPaymentAvailableList() {
            getPaymentAvailableList().then((res) => {
                if (res.code === 200) {
                    this.feed_list = res.data.feed_list
                    this.pay_list = res.data.pay_list
                    this.max_price = Number(res.data.max_price).toFixed(0)
                    this.min_price = Number(res.data.min_price).toFixed(0)
                    this.times = res.data.times
                    this.pc_image = res.data.pc_image
                    this.pc_jump_url = res.data.pc_jump_url
                }
            }).catch((err) => {
                console.log('捕获异常', err)
            })
        },
        // 月卡支付接口
        createCardCharge() {
            createCardCharge({
                card_id: this.card_id,
                pay_type: this.isMethod,
            }).then((res) => {
                if (res.code === 200) {
                    this.pay_ewm = res.data.pay_url
                    this.pay_money = Number(res.data.money).toFixed(0)
                    this.pay_type = res.data.pay_type_msg
                    this.orderID = res.data.out_trade_no
                    this.$czc('PC购买月卡页面-发起订单', '点击', '生成月卡订单')
                    this.czc_type = 2
                    this.payCode()
                    this.bool = true
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500
                });
                this.bool = true
            })
        },
        // 包服卡支付接口
        createMouthCharge() {
            createMouthCharge({
                money: this.mouth_price,
                pay_type: this.isMethod,
            }).then((res) => {
                if (res.code === 200) {
                    this.pay_ewm = res.data.pay_url
                    this.pay_money = Number(res.data.money).toFixed(0)
                    this.pay_type = res.data.pay_type_msg
                    this.orderID = res.data.out_trade_no
                    this.$czc('PC购买包服卡页面-发起订单', '点击', '生成包服卡订单')
                    this.czc_type = 4
                    this.payCode()
                    this.bool = true
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500
                });
                this.bool = true
            })
        },
        //发起支付接口
        initiatePayment() {
            initiatePayment({
                money: this.moneyValue,
                contact: this.contact,
                pay_type: this.isMethod,

            }).then((res) => {
                if (res.code === 200) {
                    this.pay_ewm = res.data.pay_url
                    this.pay_money = Number(res.data.money).toFixed(0)
                    this.pay_type = res.data.pay_type_msg
                    this.orderID = res.data.out_trade_no
                    this.$czc('PC充值页面-发起订单', '点击', '生成充值订单')
                    this.czc_type = 1
                    this.payCode()
                    this.bool = true
                }
            }).catch((err) => {
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500
                });
                this.bool = true
            })
            // this.bool = true
        },
        // 月卡立即支付
        cardPay() {
            if (localStorage.getItem('token')) {
                if (this.userInfo.user_idcard) {
                    if (this.bool) {
                        this.bool = false
                        this.$message({
                            message: "订单生成中，请稍后···",
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.createCardCharge()
                            }
                        })
                    }
                } else {
                    this.$message({
                        message: "您还未实名，请先实名后使用该功能！",
                        type: 'warning',
                        duration: 1500,
                        onClose: () => {
                            this.$router.push({
                                path: '/member/realName'
                            })
                        }
                    })
                }
            } else {
                this.$message({
                    message: "您还未登录，请先登录！",
                    type: 'warning',
                    duration: 2000,
                    onClose: () => {
                        this.$refs.login.open()
                    }
                });
            }
        },
        //包服卡立即支付
        mouthPay() {
            if (localStorage.getItem('token')) {
                if (this.userInfo.user_idcard) {
                    if (this.bool) {
                        this.bool = false
                        this.$message({
                            message: "订单生成中，请稍后···",
                            type: 'success',
                            duration: 1500,
                            onClose: () => {
                                this.createMouthCharge()
                            }
                        })
                    }
                } else {
                    this.$message({
                        message: "您还未实名，请先实名后使用该功能！",
                        type: 'warning',
                        duration: 1500,
                        onClose: () => {
                            this.$router.push({
                                path: '/member/realName'
                            })
                        }
                    })
                }
            } else {
                this.$message({
                    message: "您还未登录，请先登录！",
                    type: 'warning',
                    duration: 2000,
                    onClose: () => {
                        this.$refs.login.open()
                    }
                });
            }
        },
        //立即支付
        pay() {
            if (localStorage.getItem('token')) {
                // if (this.userInfo.user_idcard) {
                    if (this.bool) {
                        if (this.moneyValue * 1 >= this.min_price * 1 && this.moneyValue * 1 <= this.max_price * 1) {
                            this.bool = false
                            this.$message({
                                message: "订单生成中，请稍后···",
                                type: 'success',
                                duration: 1500,
                                onClose: () => {
                                    this.initiatePayment()
                                }
                            })
                        } else {
                            if (this.moneyValue * 1 < this.min_price * 1) {
                                this.$message({
                                    message: "充值金额小于最低限额",
                                    type: 'warning',
                                    duration: 1500
                                });
                            } else {
                                this.$message({
                                    message: "充值金额超限",
                                    type: 'warning',
                                    duration: 1500
                                });
                            }

                        }
                    }
                // } else {
                //     this.$message({
                //         message: "您还未实名，请先实名后使用该功能！",
                //         type: 'warning',
                //         duration: 1500,
                //         onClose: () => {
                //             this.$router.push({
                //                 path: '/member/realName'
                //             })
                //         }
                //     })
                // }
            } else {
                this.$message({
                    message: "您还未登录，请先登录！",
                    type: 'warning',
                    duration: 2000,
                    onClose: () => {
                        this.$refs.login.open()
                    }
                });
            }


        },
        // 支付二维码弹窗
        payCode() {
            var that = this
            var pay_tc = $('.pay_tc')
            var LayIndex = layer.open({
                type: 1,
                content: pay_tc,
                anim: false,
                fixed: true,
                shade: 0.6,
                title: 0,
                scrollbar: true,
                shadeClose: false,
                isOutAnim: false,
                area: [auto, auto],
                success: function () {
                    // console.log(that)
                    that.timer = setInterval(() => {
                        setTimeout(that.verifyOrderStatus(), 0)
                        if ($(".pay_tc").is(':hidden')) {
                            //处理业务  
                            clearInterval(that.timer)
                        }
                    }, 2000)
                },
            });
        },
        // 订单状态轮巡接口
        verifyOrderStatus() {
            verifyOrderStatus({
                out_trade_no: this.orderID
            }).then((res) => {
                if (res.code === 200) {
                    clearInterval(this.timer)
                    this.timer = ''
                    if (this.czc_type == 1) {
                        this.$czc('PC充值页面-支付二维码弹窗', '扫码', '订单支付成功')
                    }
                    else if (this.czc_type == 2) {
                        this.$czc('PC月卡页面-支付二维码弹窗', '扫码', '订单支付成功')
                    }
                    else if (this.czc_type == 4) {
                        this.$czc('PC包服卡页面-支付二维码弹窗', '扫码', '订单支付成功')
                    }
                    this.$message({
                        message: res.msg,
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                            this.getUserCard()
                            location.reload()
                        },
                    })
                }
            }).catch((err) => {
                console.log('捕获异常', err)

            })
        },
    },
    created() {
        if(localStorage.getItem('token')){
            this.selectMyUserCard()
        }
        this.getCardList() //获取卡牌列表
        this.getPaymentAvailableList()
        this.getMouthList() // 获取包服卡列表
        // 判断shif
        if (this.pc_jump_url) {
            this.tab = '_blank'
        }
    },
    mounted(){
       
    }
}
</script>
  
<style scoped>
/* 公共 */
* {
    /* margin: 0; */
}
.ad_banner{
    width: 600px;
    height: 250px;
}
.bag_main{
    height: 100%;
    position: relative;
}
.isClick {
    background: #AC8747;
    filter: grayscale(100%);
}
.my_mg {
    margin-top: 35px;
}

/* labe字样 */
.content_left_font {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #4D4D4D;
    text-decoration: none;
}

/* 数字color */
.number_color {
    color: #CF1F1F;
}

.become_time {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 300;
    color: #666666;
    margin-left: 10px;
}

/* 选项卡点击 */
.btn_active {
    display: block !important;
}

/*  */
.bag_content {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
}

.cz_color {
    color: #FFFFFF !important;
    background: none !important;
    -webkit-text-fill-color: #FFFFFF !important;
    position: absolute !important;
}

.font_color {
    position: relative;
    z-index: 10;
    color: #A36F28;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    background: linear-gradient(0deg, #A36F28 0%, #C98B36 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
}

.bag_box {
    padding: 80px 40px 85px;
    background: #FFFFFF;
    border-radius: 0 0 4px 4px;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.bag_title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(178, 147, 96, 0.2);
    background: linear-gradient(0deg, #F6F0E9, #FFFFFF);
    border-radius: 4px 4px 0px 0px;
    padding: 12px 12px 0;
    position: absolute;
}

.fgx {
    width: 1px;
    height: 16px;
    background: #B2B2B2;
    margin: 0 15px;
}

.cz_class {
    display: flex;
    align-items: center;
    justify-content: start;
}

.cz_item {
    position: relative;
    margin-right: 18px;
}

.btn_bg {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
}

.cz_class h4 {
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin: 0;
}

.tip {
    display: flex;
    align-items: center;
    justify-content: end;
}

.tip_icon {
    width: 13px;
    height: 13px;
    border: 1px solid #806437;
    border-radius: 50%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    margin-right: 5px;
}

.tip span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
}

.content_left {
    display: flex;
    flex-direction: column;
    align-items: start;
    /* justify-content: space-between; */
    height: 100%;
    margin-top: 10px;
}

.money_items {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
}

.money_font {
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #4E4E4E;
    text-align: center;
    text-decoration: none;
}

.jb_box {
    position: absolute;
    right: 0;
    top: -1%;
    z-index: 10;
}

.jb_box span {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFBD9;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    left: 78%;
    top: 61%;
    transform: translate(-50%, -50%);
}

.jb_bg {
    position: relative;
    left: 0;
    top: 0;
    text-align: center;
}

.money {
    margin-right: 10px;
    margin-top: 10px;
    position: relative;
    transition: all 0.5s;
    cursor: pointer;
}

.money:nth-last-child(2) {
    margin-right: 0;
}

.card_bg {
    position: relative;
    top: 0;
    left: 0;
}

.card_content {
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.card_title {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #312B26;
}

.host_num {
    font-size: 32px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #744200;
}

.host_num span {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #744200;
}

.send_title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #A8957A;
    margin-bottom: 10px;
}
.old_price{
    position: relative;
    margin-right: 20px;
}
.huaheng{
    width: 110%;
    height: 2px;
    background: #cccccc;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}
.old_price_num{
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #cccccc;
}
.new_price_num{
     font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: green;
}
.new_spade_money{
    background: rgb(245, 213, 172, 0.7);
    border-radius: 4px;
    padding: 5px 10px;
    position: relative;
}
.xsth{
    position: absolute;
    top: -12px;
    right: -5px;
}
.spade_money {
    background: rgb(245, 213, 172, 0.7);
    border-radius: 4px;
    padding: 5px 30px;
}

.money_size {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #815626;
}

.qr {
    display: none;
    position: absolute;
    right: -1%;
    bottom: 0;
    z-index: 10;
}

.qita_box {
    display: flex;
    margin-top: 10px;
}

.money_qita {
    height: 46px;
    border: 1px solid #4D4D4D;
    border-right: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    padding: 0 10px;
    align-items: center;
}

.qita_title {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #4D4D4D;
}

.form_qita {
    border: 1px solid rgb(245, 213, 172, 0.7);
    background: rgb(245, 213, 172, 0.7);
    border-radius: 4px;
    width: 60%;
    padding: 3px 0;
    text-align: center;
}

.form_qita::placeholder {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #815626;
}

/* 兼容火狐浏览器 */
.form_qita::-moz-placeholder {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #815626;
}

/* 兼容其他浏览器 */
.form_qita:-ms-input-placeholder {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #815626;
}

/* 兼容IE浏览器 */
.form_qita::-ms-input-placeholder {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #815626;
}

/* 兼容所有浏览器 */
.form_qita::placeholder {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #815626;
}

.money_unifo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.lv_send {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.receipt {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lv_m {
    margin-right: 30px;
}

.lv_m:nth-last-child(0) {
    margin-right: 0;
}

.add_send h4 {
    margin-left: 10px;
}

.lv_hop {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #9A7436;
    text-decoration: none;
    margin-left: 10px;
}

.qq {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-top: 30px;
}

.qq_input {
    width: 200px;
    height: 50px;
    background: rgba(246, 242, 235, 0);
    border: 2px solid #4D4D4D;
    border-radius: 4px;
    outline-style: none;
}

.qq_input:focus {
    background: rgba(245, 243, 240, 0) !important;
    border: 2px solid #F5CA78 !important;
}

.payment {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-top: 20px;
}

.method {
    width: 200px;
    height: 50px;
    background: rgba(246, 242, 235, 0);
    border: 2px solid #E0E0E0;
    border-radius: 4px;
    margin-right: 30px;
    position: relative;
    transition: all 0.5s;
}

.method:hover {
    transition: all 0.5s;
    border: 2px solid #F5CA78
}

.hit_pay {
    border: 2px solid #F5CA78 !important;
}

.method_img {
    height: 30px;
    margin-right: 5px;
}

.submit_btn {
    padding: 15px 120px;
    border-radius: 8px;
    margin-top: 20px;
}
.lq_btn {
    padding: 15px 120px;
    border-radius: 8px;
    margin-top: 40px;
}
.btn_font {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    text-decoration: none;
}

.btn_color {
    transition: all 0.5s;
    background: #B5945B;
}

.btn_color:hover {
    transition: all 0.5s;
    filter: brightness(1.2);
}

/*  */
.content_right {
    display: flex;
    align-items: end;
    height: 100%;
    margin-top: 40px;
}

.beauty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.beauty_img {
    border: 1px solid #CEBFA5;
    border-radius: 8px;
    padding: 8px;
}

.beauty_title {
    font-size: 14px;
    font-family: SimSun;
    font-weight: 400;
    color: #4D4A45;
    margin-bottom: 10px;
}

.time {
    font-size: 14px;
    font-family: SimSun;
    font-weight: 400;
    color: #CF1F1F;
    width: 100%;
    margin-top: 10px;
    text-align: center;
}

/* 月卡 */
.card_item>.row>.col-3 {
    padding-right: 0;
}

.card_index {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.card_img {
    cursor: pointer;
}

.my_card_box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: start;
}
.my_card_img{
    width: 43.5%;
    margin-right: 10px;
}

.ykfl_box {
    position: relative;
}

.ykfl_content {
    position: absolute;
    z-index: 20;
    padding: 20px 15px;
    height: 100%;
}

.ykfl_title {
    display: flex;
    align-items: flex-end;
    justify-content: start;
}

.ykfl_title h4 {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #806437;
    margin: 0;
}

.ykfl_title span {
    font-size: 10px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #806437;
    margin-left: 10px;
}

.yk_num {
    position: relative;
    margin-top: 20px;
}
.yk_tip{
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #806437;
    position: absolute;
    z-index: 20;
    left: 8%;
    bottom: -3px;
}
.ds_bg {
    position: relative;
    top: 0;
    left: 0;
    width: 33%;
}
.yk_title{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #806437;
    margin: 10px 5px 0;
}
.yk_sm{
    display: flex;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #684D21;
    margin: 5% 5px 0;
}
.suoming{
    max-width: 47%;
}
.yk_bg {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
}

.people {
    position: absolute;
    z-index: 10;
    right: 0;
    bottom: 0;
    width: 60%;
}

@media (max-width:1700px) {
    .money:nth-last-child(2) {
        margin-right: 10px;
    }
}

/*  */
@media (max-width:1600px) {
    .yk_tip{
        font-size: 25px;
    }
    /* 充值卡 */
    .card_title {
        font-size: 14px;
    }

    .host_num {
        font-size: 30px;
    }

    .host_num span {
        font-size: 16px;
    }

    .send_title {
        font-size: 12px;
    }

    .money_size {
        font-size: 14px;
    }

    /*  */
    .tip span {
        font-size: 12px;
    }

    .bag_title {}

    .content_left_font {
        font-size: 14px;
    }

    .money_font {
        font-size: 14px;
    }

    /*  */
    .lv_hop {
        font-size: 14px;
    }

    .qq_input {
        height: 40px;
    }

    .btn_font {
        font-size: 14px;
    }

    .method_img {
        height: 25px;
    }

    .submit_btn {
        margin-left: 70px;
    }

    /*  客服*/
    .beauty_ewm {
        width: 100px;
    }
}
@media (max-width:1400px) {
    /* 月卡 */
    .font_color {
        font-size: 14px;
    }
    .ykfl_title h4{
        font-size: 16px;
    }
    .yk_sm{
        margin: 2% 5px 0;
    }
}
@media (max-width:1300px) {
    
    /* 充值卡 */
    .jb_box span {
        left: 80%;
        top: 72%;
        font-size: 12px;
    }

    .card_title {
        font-size: 12px;
    }

    .host_num {
        font-size: 26px;
    }

    .host_num span {
        font-size: 14px;
    }

    .send_title {
        font-size: 12px;
    }

    .money_size {
        font-size: 12px;
    }

    /*  */

    .bag_box {
        padding: 80px 40px 75px;
    }

    .content_left_font {
        font-size: 12px;
    }

    .money_font {
        font-size: 12px;
    }

    .qita_title {
        font-size: 12px;
    }

    .money_qita {
        height: 35px;
    }

    .submit_btn {
        margin-left: 60px;
    }

    .btn_font {
        font-size: 12px;
    }

    .submit_btn {
        padding: 10px 100px;
    }
}
@media (max-width:1200px) {
    /* 月卡 */
    .ykfl_content{
        padding: 10px 15px;
    }
    .yk_num{
        margin-top: 10px;
    }
    .yk_tip {
        font-size: 20px;
    }
    .yk_title {
        font-size: 12px;
    }
    .suoming{
        max-width: 60%;
    }
}
@media (max-width:1100px) {
    .beauty_title {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .time {
        font-size: 12px;
        margin-top: 5px;
    }

    .beauty_ewm {
        width: 80px;
    }
    
}
</style>