<template>
  <div class="headers">
    <img src="/header/img/shouye4.png" width="100%">
    <div class="menu">
        <div class="menu_box container">
            <div class="menu_left">
                <ul>
                    <li class="item_li">
                        <img class="logo" src="/header/img/logo.png" height="36px">
                    </li>
                    <li class="item_li">
                        <a @click="toHome" class="top_title">网站首页</a>
                    </li>
                    <li>
                        <router-link to="/member" class="top_title">会员中心</router-link>
                    </li>
                    <li>
                        <a class="top_title" @click="click_czc">平台充值</a>
                    </li>
                    <li>
                        <a class="top_title" v-if="material.shouquan" :href="material.shouquan !=='' ? img_url + material.shouquan :'javascript:0'" target="_blank">平台授权</a>
                    </li>
                    <li>
                        <router-link to="/information" class="top_title">新闻咨询</router-link>
                    </li>
                    <li>
                        <a @click="toCharts" class="top_title">排行榜</a>
                    </li>
                    <li v-if="show_tutoral">
                        <router-link to="/tutorial" class="top_title">新手教程</router-link>
                    </li> 
                    <li>
                        <router-link to="/helps" class="top_title">帮助</router-link>
                    </li>
                </ul>
            </div>
            <div class="menu_left">
                <button v-if="unLogin" class="btn btn-primary " @click="LoginOne">登入/注册</button>
                <button v-if="isLogin" class="btn btn-success " @click="login_back">退出登录</button>
            </div>
        </div>
    </div>
    <Login ref="login" @closeLogin="closeLogin"></Login>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Login from "../components/Login.vue";
    export default {
        name:'Header3',
        components:{
            Login
        },
        data() {
            return {
                show_tutoral:false, //是否显示新手教程
                materials: JSON.parse(localStorage.getItem('material')) || null,
            }
        },
        computed:{
            ...mapState([
                'material',
                'isLogin',
                'unLogin',
            ])
        },
        watch: {
            materials: {
                handler(newVal, oldVal) {
                    if(this.materials){
                        console.log('我是物料',this.materials.pc_tutorial_banner)
                        if(this.materials.pc_tutorial_banner.img || this.materials.pc_tutorial_banners.img || this.materials.pc_tutorial_bannerss.img){
                            this.show_tutoral = true
                        }else{
                            this.show_tutoral = false
                        }
                    }
                },
                immediate: true // 立即执行一次，用于初始化
            }
        },
        methods:{
            closeLogin(){
                $("body").parent().css("overflow-y","auto")
                $("body").parent().css("overflow-x","auto")
            },
            login_back(){
                localStorage.setItem("token",'')
                localStorage.setItem('userInfo','')
                location.reload()
            },
            LoginOne(){
                this.$refs.login.open()
                $("body").parent().css("overflow-y","hidden")
                $("body").parent().css("overflow-x","hidden")
            },
            click_atlas(){
                this.$router.push({
                    path:'/atlas'
                })
                this.$czc('PC首页顶部导航通关成就', '点击', '访问通关成就')
            },
            click_czc(){
                this.$router.push({
                    path:'/member/wallet'
                })
                this.$czc( 'PC首页顶部导航平台充值', '点击', '访问平台充值')
            },
            toHome(){
                this.$router.push({
                    path:'/home'
                })
                location.reload()
            },
            toPointsMall(){
                this.$router.push({
                    path:'/pointsMall'
                })
                localStorage.setItem('mall_id','1')
                this.$czc('PC首页顶部导航灵符商城', '点击', '访问灵符商城')
            },
            toCharts(){
                this.$router.push({
                    path:'/chartsing'
                })
                this.$czc( 'PC子页面排行榜', '点击', '访问排行榜')
            }
        },
        mounted(){
            // this.$nextTick(() =>{
            //     const material = JSON.parse(localStorage.getItem('material'))
            //     console.log('我是物料',material.pc_tutorial_banner)
            //     if(material.pc_tutorial_banner.img || material.pc_tutorial_banners.img || material.pc_tutorial_bannerss.img){
            //         this.show_tutoral = true
            //     }else{
            //         this.show_tutoral = false
            //     }
            // })
        }
    }
</script>

<style scoped>
.btn-primary{
    background-color: #2846a7;
    border-color: #2846a7;
}
.headers{
    position: relative;
}
.logo{
    margin-right: 12px;
}
.menu{
    width: 100%;
    background: rgba(43, 43, 52, .6);
    position: absolute;
    left: 0;
    top: auto;
    z-index: 50;
}
.menu_bg{
    position:absolute;
    top: 0;
    left: 0;
    z-index: -10;
}
.top_title{
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    margin-right: 12px;
    text-decoration: none;
    transition: all 0.5s;
}
.top_title:hover{
    color: #B4935A ;
    transition: all 0.5s;
}
.menu_box{
    display: flex;
    align-items: center;
    justify-content:space-between;
    width: 100%;
    overflow: hidden;
    padding: 19px 0 ;
}
.banner_bg{
    width: 100%;
    position: relative;
    z-index: -99;
}
.menu_left{
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
}
.menu_left ul{
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin: 0;
    padding: 0;
}
.menu_left ul li{
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu_left ul li:nt{
    margin: 0;
}

</style>